import(/* webpackMode: "eager", webpackExports: ["DraftQuoteContextProvider","useDraftQuote"] */ "/vercel/path0/apps/web/client/context/draft-quote/DraftQuoteContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/vercel/path0/apps/web/client/context/react-query-provider/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteClientWrapper"] */ "/vercel/path0/apps/web/client/context/site-client-wrapper/SiteClientWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoryblokProvider"] */ "/vercel/path0/apps/web/client/context/storyblok-provider/StoryblokProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/EFCircularSub-Book.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/EFCircularSub-BookItalic.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"./fonts/EFCircularSub-Light.woff2\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"./fonts/EFCircularSub-LightItalic.woff2\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"./fonts/EFCircularSub-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/EFCircularSub-MediumItalic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"./fonts/EFCircularSub-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./fonts/EFCircularSub-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"}]}],\"variableName\":\"efCircular\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/localization/src/i18n-context-provider/I18NContextProviderClient.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/theme/reset.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/theme/adv/adv-light.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/theme/gat/gat-light.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/theme/ub/ub-light.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["DialpadButton"] */ "/vercel/path0/packages/web-components/src/chat-scripts/DialpadButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialpadConditionalRender"] */ "/vercel/path0/packages/web-components/src/chat-scripts/DialpadConditionalRender.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DriftScript"] */ "/vercel/path0/packages/web-components/src/chat-scripts/DriftScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ZendeskScript"] */ "/vercel/path0/packages/web-components/src/chat-scripts/ZendeskScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OptinMonsterScript"] */ "/vercel/path0/packages/webforms/src/OptinMonsterScript.tsx");
