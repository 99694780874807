"use client";
import { gtmSendEvent } from "@wojo/analytics";
import { usePathname } from "next/navigation";
import Script from "next/script";
import { useCreateHelpCenterMessagingTokenMutation } from "../generated/graphql";
import { ChatWindow } from "./types";

declare var window: ChatWindow;

/**
 * OptinMonster's default z-index used for campaigns
 */
export const OPTINMONSTER_Z_INDEX = 666666666;

/**
 * Z-index 1 greater than OptinMonster
 */
export const ZENDESK_MESSAGING_Z_INDEX = OPTINMONSTER_Z_INDEX + 1;

export const ZendeskScript: React.FC = () => {
    const pathname = usePathname();
    const [createToken] = useCreateHelpCenterMessagingTokenMutation();

    if (
        !process.env.NEXT_PUBLIC_ZENDESK_MESSAGING_ID ||
        !pathname.includes("myaccount")
    ) {
        return null;
    }

    const onLoad = () => {
        if (window.zE) {
            window.zE("messenger:set", "zIndex", ZENDESK_MESSAGING_Z_INDEX);
            createToken({
                onCompleted: (data) => {
                    const token = data.createHelpCenterMessagingToken?.token;
                    if (token && window.zE) {
                        window.zE(
                            "messenger",
                            "loginUser",
                            function (callback: (token: string) => void) {
                                callback(token);
                            },
                        );
                    }
                },
                onError: () => {
                    // prevent the page from breaking if the mutation fails
                },
            });

            window.zE("messenger:on", "open", function () {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "OPENED",
                        ga4_object: "CHAT",
                    },
                });
            });

            window.zE("messenger:on", "close", () => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "CLOSED",
                        ga4_object: "CHAT",
                    },
                });
            });
        }
    };

    return (
        <Script
            id="ze-snippet"
            strategy="lazyOnload"
            onLoad={onLoad}
            src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_MESSAGING_ID}`}
        />
    );
};
