"use client";

import { usePathname } from "next/navigation";
import React from "react";

/**
 * The Dialpad script does not load properly unless it uses the 'beforeInteractive' strategy,
 * which is restricted to scripts in the root layout.
 * However, we want to conditionally render that root script based on path.
 */
export const DialpadConditionalRender: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const pathname = usePathname();
    if (
        !process.env.NEXT_PUBLIC_DIALPAD_ID ||
        !process.env.NEXT_PUBLIC_DIALPAD_EMAIL ||
        pathname.includes("myaccount")
    ) {
        return null;
    }
    return children;
};
