"use client";
import { useMediaQuery } from "@mantine/hooks";
import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import { Button } from "@wojo/ui";
import { usePathname } from "next/navigation";
import React, { useEffect } from "react";
import { FaComments } from "react-icons/fa";
import styles from "./ChatButton.module.scss";
import { ChatWindow } from "./types";

declare var window: ChatWindow;

export const DialpadButton: React.FC = () => {
    const pathname = usePathname();
    const { global } = useI18n("global");
    const isDesktop = useMediaQuery("(min-width: 576px)", false);

    // This is literally how dialpad does it
    useEffect(() => {
        if (window.kpde !== undefined) {
            window.kpde.server = "https://us-central.dx.dialpad.com";
            const originalOnChatStart = window.kpde.on_chat_started;
            window.kpde.on_chat_started = (...args) => {
                originalOnChatStart.apply(window.kpde, args);
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "START_CONVERSATION",
                        ga4_object: "CHAT",
                    },
                });
            };
            const originalOnChatClosed = window.kpde.on_chat_closed;
            window.kpde.on_chat_closed = (...args) => {
                originalOnChatClosed.apply(window.kpde, args);
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "CLOSED",
                        ga4_object: "CHAT",
                    },
                });
            };
            const originalOnChatLoaded = window.kpde.on_chat_loaded;
            window.kpde.on_chat_loaded = (...args) => {
                originalOnChatLoaded.apply(window.kpde, args);
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "OPENED",
                        ga4_object: "CHAT",
                    },
                });
            };
            return () => {
                // clean up so tracking doesn't keep getting chained on
                if (window.kpde) {
                    window.kpde.on_chat_started = originalOnChatStart;
                    window.kpde.on_chat_closed = originalOnChatClosed;
                    window.kpde.on_chat_loaded = originalOnChatLoaded;
                }
            };
        }
    }, []);

    if (
        !process.env.NEXT_PUBLIC_DIALPAD_ID ||
        !process.env.NEXT_PUBLIC_DIALPAD_EMAIL ||
        pathname.includes("myaccount")
    ) {
        return null;
    }
    return (
        <div className={styles["dialpad-button-wrapper"]}>
            <Button
                className={styles["dialpad-button"]}
                type="button"
                variant="secondary"
                id="kpd_koopidtag"
                data-kpdchannelid={process.env.NEXT_PUBLIC_DIALPAD_ID}
                data-kpdembedded="true"
                data-kpdguest="true"
                data-kpdprovemail={process.env.NEXT_PUBLIC_DIALPAD_EMAIL}
            >
                {isDesktop && global.chatButtonLabel.l()}
                <FaComments aria-hidden size={18} />
            </Button>
        </div>
    );
};
