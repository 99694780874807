"use client";

import {
    Cookie,
    getClientId,
    gtmSendEvent,
    WebConversionCookie,
} from "@wojo/analytics";
import Cookies from "js-cookie";
import { useEffect } from "react";

type OimEvent = Event & {
    detail?: {
        Campaign?: {
            id: string;
            Form?: {
                form: HTMLDivElement | null;
            };
        };
    };
};

type CustomVariableEvent = Event & {
    detail?: {
        Dtr?: {
            setCustomVariable?: (key: string, value?: string) => void;
        };
    };
};

export const useOptinMonster = () => {
    useEffect(() => {
        const handleCustomVariables = (event?: CustomVariableEvent) => {
            const cookie: WebConversionCookie = JSON.parse(
                Cookies.get(Cookie.webConversion) || "{}",
            );
            const gclid = Cookies.get("gclid");
            event?.detail?.Dtr?.setCustomVariable?.("gclid", gclid);
            event?.detail?.Dtr?.setCustomVariable?.(
                "utm_source",
                cookie.UTMSource,
            );
            event?.detail?.Dtr?.setCustomVariable?.(
                "utm_medium",
                cookie.UTMMedium,
            );
            event?.detail?.Dtr?.setCustomVariable?.(
                "utm_campaign",
                cookie.UTMCampaign,
            );
            event?.detail?.Dtr?.setCustomVariable?.(
                "utm_content",
                cookie.UTMContent,
            );
            event?.detail?.Dtr?.setCustomVariable?.(
                "utm_group",
                cookie.UTMGroup,
            );
            event?.detail?.Dtr?.setCustomVariable?.("utm_term", cookie.UTMTerm);
            event?.detail?.Dtr?.setCustomVariable?.(
                "gaClientId",
                getClientId(),
            );
        };

        const handleCampaignAfterShow = (e: OimEvent) => {
            const campaignId = e.detail?.Campaign?.id;
            // `form` is the HTML element (usually <div>) in the optin monster campaign code
            const isLeadForm = !!e.detail?.Campaign?.Form?.form;
            if (!campaignId || !isLeadForm) {
                return;
            }

            gtmSendEvent({
                event: "track",
                ga4Tracking: {
                    ga4_object: "LEAD_FORM",
                    ga4_action: "VIEWED",
                    leadFormId: campaignId,
                },
            });
        };

        const handleOptinSuccess = (e: OimEvent) => {
            const campaignId = e.detail?.Campaign?.id;
            // `form` is the HTML element (usually <div>) in the optin monster campaign code
            const isLeadForm = !!e.detail?.Campaign?.Form?.form;
            if (!campaignId || !isLeadForm) {
                return;
            }

            gtmSendEvent({
                event: "track",
                ga4Tracking: {
                    ga4_object: "LEAD_FORM",
                    ga4_action: "SUBMITTED",
                    leadFormId: campaignId,
                },
            });
        };

        document.addEventListener("om.Dtr.init", handleCustomVariables);
        document.addEventListener(
            "om.Campaign.afterShow",
            handleCampaignAfterShow,
        );
        document.addEventListener("om.Optin.success", handleOptinSuccess);

        return () => {
            document.removeEventListener(
                "om.Campaign.afterShow",
                handleCustomVariables,
            );
            document.removeEventListener(
                "om.Campaign.afterShow",
                handleCampaignAfterShow,
            );
            document.removeEventListener(
                "om.Optin.success",
                handleOptinSuccess,
            );
        };
    }, []);
};
