"use client";
import { useMediaQuery } from "@mantine/hooks";
import { gtmSendEvent } from "@wojo/analytics";
import { useAuth } from "@wojo/auth";
import cookies from "js-cookie";
import { usePathname } from "next/navigation";
import Script from "next/script";
import React, { useEffect, useState } from "react";
import { ChatWindow } from "./types";

declare var window: ChatWindow;

export type DriftEvent = {
    event: string;
    callback: (event: { conversationId: string; campaignId: string }) => void;
};

export type DriftAttributes = {
    email?: string;
    cid?: string;
    gclid?: string;
};

const omitPaths = [
    "/mobile-app/payments-frame",
    "/zendesk-authenticate",
    "/zendesk-dialpad-connect",
    "/zendesk-login",
];

/**
 * Adds Drift modal to the webpage and handles Drift events.
 */
export const DriftScript: React.FC = () => {
    const [ready, setReady] = useState(false);
    const pathname = usePathname();
    const { customer } = useAuth();
    const { email, _id: userId } = customer || {};

    let urlParams: URLSearchParams | null = null;
    if (typeof window !== "undefined") {
        urlParams = new URLSearchParams(window.location.search);
    }
    // Maintain backwards compatibility for multiple cid params
    const cid = urlParams?.getAll("cid")[0] || cookies.get("cid");
    // Maintain backwards compatibility for multiple gclid params
    const gclid = urlParams?.getAll("gclid")[0] || cookies.get("gclid");

    const driftAttributes: DriftAttributes = {
        cid,
        gclid,
        email: email || undefined,
    };

    // 560px is the breakpoint for mobile on Drift it seems.
    const isMobile = useMediaQuery("(max-width: 560px)", false);

    const driftEvents: DriftEvent[] = [
        {
            event: "message:sent",
            callback: (event) => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "MESSAGE_SENT",
                        ga4_object: "CHAT",
                        conversationId: event.conversationId,
                    },
                });
            },
        },
        {
            event: "startConversation",
            callback: (event) => {
                // Fighting Drift, which resets the styles on this event
                setTimeout(() => {
                    if (isMobile) {
                        document
                            .getElementById("drift-frame-chat")
                            ?.style.setProperty("top", "16px", "important");
                        document
                            .getElementById("drift-frame-chat")
                            ?.style.setProperty("bottom", "68px", "important");
                        document
                            .getElementById("drift-frame-chat")
                            ?.style.setProperty("height", null, "important");
                        document
                            .getElementById("drift-frame-chat")
                            ?.style.setProperty(
                                "max-height",
                                null,
                                "important",
                            );
                    }
                }, 100);

                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "START_CONVERSATION",
                        ga4_object: "CHAT",
                        conversationId: event.conversationId,
                    },
                });
            },
        },
        {
            event: "emailCapture",
            callback: () => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "EMAIL_CAPTURE",
                        ga4_object: "CHAT",
                    },
                });
            },
        },
        {
            event: "welcomeMessage:open",
            callback: () => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "WELCOME_MESSAGE_OPEN",
                        ga4_object: "CHAT",
                    },
                });
            },
        },
        {
            event: "welcomeMessage:close",
            callback: () => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "WELCOME_MESSAGE_CLOSE",
                        ga4_object: "CHAT",
                    },
                });
            },
        },
        {
            event: "sidebarOpen",
            callback: () => {
                // Fighting Drift, which resets the styles on this event
                setTimeout(() => {
                    if (isMobile) {
                        document
                            .getElementById("drift-frame-chat")
                            ?.style.setProperty("top", "16px", "important");
                        document
                            .getElementById("drift-frame-chat")
                            ?.style.setProperty("bottom", "68px", "important");
                        document
                            .getElementById("drift-frame-chat")
                            ?.style.setProperty("height", null, "important");
                        document
                            .getElementById("drift-frame-chat")
                            ?.style.setProperty(
                                "max-height",
                                null,
                                "important",
                            );
                    }
                }, 100);

                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "SIDEBAR_OPEN",
                        ga4_object: "CHAT",
                    },
                });
            },
        },
        {
            event: "sidebarClose",
            callback: () => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "SIDEBAR_CLOSE",
                        ga4_object: "CHAT",
                    },
                });
            },
        },
        {
            event: "campaign:open",
            callback: (event) => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "CAMPAGIN_OPEN",
                        ga4_object: "CHAT",
                        campaignId: event.campaignId,
                    },
                });
            },
        },
        {
            event: "campaign:dismiss",
            callback: (event) => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "CAMPAIGN_DISMISS",
                        ga4_object: "CHAT",
                        campaignId: event.campaignId,
                    },
                });
            },
        },
        {
            event: "campaign:click",
            callback: (event) => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "CAMPAGIN_CLICK",
                        ga4_object: "CHAT",
                        campaignId: event.campaignId,
                    },
                });
            },
        },
        {
            event: "campaign:submit",
            callback: (event) => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "CAMPAIGN_SUBMIT",
                        ga4_object: "CHAT",
                        campaignId: event.campaignId,
                    },
                });
            },
        },
        {
            event: "scheduling:meetingBooked",
            callback: () => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_action: "MEETING_BOOKED",
                        ga4_object: "CHAT",
                    },
                });
            },
        },
    ];

    useEffect(() => {
        if (ready && window.drift) {
            driftEvents.forEach((handler) => {
                window.drift?.on(handler.event, handler.callback);
            });
            if (userId) {
                window.drift.identify(userId, driftAttributes);
            } else {
                window.drift.on("ready", () => {
                    window.drift?.api.setUserAttributes(driftAttributes);
                });
            }
        }

        return () => {
            driftEvents.forEach((handler) => {
                window.drift?.off(handler.event, handler.callback);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, ready, userId]);

    if (
        !process.env.NEXT_PUBLIC_DRIFT_APP_ID ||
        pathname.includes("myaccount") ||
        omitPaths.includes(pathname)
    ) {
        return null;
    }

    return (
        <>
            <style>{`
                    #drift-frame-chat {
                        bottom: 68px !important;
                        max-height: calc(100% - 68px);
                    }
            `}</style>
            <Script
                id="show-drift"
                strategy="lazyOnload"
                onReady={() => {
                    setTimeout(() => {
                        setReady(true);
                    }, 0);
                }}
                dangerouslySetInnerHTML={{
                    __html: `
                        !function() {
                            var t = window.driftt = window.drift = window.driftt || [];
                            if (!t.init) {
                                if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
                                t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
                                t.factory = function(e) {
                                return function() {
                                    var n = Array.prototype.slice.call(arguments);
                                    return n.unshift(e), t.push(n), t;
                                };
                                }, t.methods.forEach(function(e) {
                                t[e] = t.factory(e);
                                }), t.load = function(t) {
                                    var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                                    o.type = "text/javascript", o.defer = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                                    var i = document.getElementsByTagName("script")[0];
                                    i.parentNode.insertBefore(o, i);
                                };
                            }
                        }();
                        drift.SNIPPET_VERSION = '0.3.1';
                        drift.load('${process.env.NEXT_PUBLIC_DRIFT_APP_ID}');
                    `,
                }}
            />
        </>
    );
};
