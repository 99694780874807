"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";

export const RouteChangeListener: React.FC = () => {
    const pathname = usePathname();

    useEffect(() => {
        const omKey = `om${process.env.NEXT_PUBLIC_OPTINMONSTER_ACCOUNT}_${process.env.NEXT_PUBLIC_OPTINMONSTER_USER}`;
        (
            window as unknown as {
                [key: string]: { reset: Function } | undefined;
            }
        )[omKey]?.reset();
    }, [pathname]);

    return null;
};
