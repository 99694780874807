"use client";

import { usePathname } from "next/navigation";
import Script from "next/script";
import { RouteChangeListener } from "./RouteChangeListener";
import { useOptinMonster } from "./use-optin-monster";

const omitPaths = [
    "/mobile-app/payments-frame",
    "/zendesk-authenticate",
    "/zendesk-dialpad-connect",
    "/zendesk-login",
];

export const OptinMonsterScript: React.FC = () => {
    useOptinMonster();
    const pathname = usePathname();

    if (
        process.env.NODE_ENV !== "development" &&
        process.env.NEXT_PUBLIC_OPTINMONSTER_USER &&
        process.env.NEXT_PUBLIC_OPTINMONSTER_ACCOUNT &&
        !omitPaths.includes(pathname)
    ) {
        return (
            <>
                <Script
                    defer
                    type="text/javascript"
                    src="https://a.omappapi.com/app/js/api.min.js"
                    data-user={process.env.NEXT_PUBLIC_OPTINMONSTER_USER}
                    data-account={process.env.NEXT_PUBLIC_OPTINMONSTER_ACCOUNT}
                />
                <RouteChangeListener />
            </>
        );
    }
    return null;
};
